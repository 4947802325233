import { dAppName } from 'config';
import withPageTitle from './components/PageTitle';
import ComingSoon from './pages/ComingSoon';
import Home from './pages/Home';
import SeedInvestors from 'pages/SeedInvestors';
import { TclSale, Zaya } from 'pages/Incubator';
import { Xlvrg } from 'pages/Incubator/Xlvrg';

export const routeNames = {
  home: '/',
  transaction: '/transaction',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  mint: '/seed-investors',
  comingsoon: '/comingsoon',
  zaya: '/incubator/zaya',
  tcl: '/incubator/tcl',
  // xlvrg: '/incubator/xlvrg',
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.comingsoon,
    title: 'ComingSoon',
    component: ComingSoon
  },
  {
    path: routeNames.mint,
    title: 'Seed Investors',
    component: SeedInvestors
  },
  {
    path: routeNames.zaya,
    title: 'ZayaAI',
    component: Zaya
  },
  {
    path: routeNames.tcl,
    title: 'The Cursed Land',
    component: TclSale
  },
  // {
  //   path: routeNames.xlvrg,
  //   title: 'XLVRG',
  //   component: Xlvrg
  // },
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
