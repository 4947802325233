/* eslint-disable import/order */
import React, { useState } from 'react';
import { useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
// import { logout } from '@multiversx/sdk-dapp/utils';
import { Navbar as BsNavbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';
// import tortillaMaiarWalletLogo from 'assets/img/maiar-tortilla-logo.svg';
import CTPLogo from 'assets/img/seed-captain-logo.png';
import './index.scss';
import { HashLink } from 'react-router-hash-link';
import clsx from 'clsx';
import { ClickAwayListener } from '@mui/material';

const Navbar = () => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = useGetIsLoggedIn();
  const [revealButtons, setRevealButtons] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  const handleRouter = (route: string) => {
    navigate(route, { replace: true });
  };

  return (
    <ClickAwayListener
      onClickAway={() => setExpanded(false)}
    >
      <div>
        <BsNavbar className='ctp-navbar px-4' collapseOnSelect expand='lg' expanded={expanded} >
          <div className='container-fluid'>
            <Link
              className='d-flex align-items-center navbar-brand mr-0'
              to={routeNames.home}
            >
              <img className="ctp-logo" src={CTPLogo} alt="CTP logo" />
              <div className="d-flex flex-column ctp-logo-title">
                <span className="dApp-name">Seed Captain Incubator</span>
                <span className="dApp-slogan">From an idea to a real project</span>
              </div>
            </Link>

            <BsNavbar.Toggle aria-controls='responsive-navbar-nav' style={{ background: "#D8D3D3" }} onClick={() => setExpanded(!expanded)} />
            <BsNavbar.Collapse id='responsive-navbar-nav' className='nav-menu-wrap'>
              <Nav className='nav-menu-box'>
                <Link to={routeNames.home} className='custom-navbar-button'>
                  Home
                </Link>
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title="NFT"
                  className='custom-navbar-button'
                  style={{ color: 'white' }}
                >
                  <NavDropdown.Item id="nav-dropdown-dark-example" onClick={() => { window.open("https://stakenft.seedcaptain.io/"); }}>
                    Stake
                  </NavDropdown.Item>
                  <div className="ml-3">
                    <NavDropdown
                      className="sub-drop-down"
                      id="dropdown-button-drop-end"
                      drop="down"
                      title="Buy"
                    >
                      <NavDropdown.Item
                        id="nav-dropdown-dark-example"
                        onClick={() => { window.open("https://deadrare.io/collection/CTPWATER-e268f8"); }}
                      >
                        DeadRare
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        id="nav-dropdown-dark-example"
                        onClick={() => { window.open("https://www.frameit.gg/marketplace/CTPWATER-e268f8/items"); }}
                      >
                        Frameit
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        id="nav-dropdown-dark-example"
                        onClick={() => { window.open("https://elrondnftswap.com/collection/CTPWATER-e268f8"); }}
                      >Elrondnftswap
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        id="nav-dropdown-dark-example"
                        onClick={() => { window.open("https://xoxno.com/collection/CTPWATER-e268f8"); }}
                      >
                        XOXO
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                  <NavDropdown.Item
                    id="nav-dropdown-dark-example"
                    onClick={() => { window.open("https://deadrare.io/collection/CTPWATER-e268f8?tab=rarities"); }}
                  >
                    Rarity
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title="$CTP Token"
                  className='custom-navbar-button'
                  style={{ color: 'white' }}
                >
                  <NavDropdown.Item
                    id="nav-dropdown-dark-example"
                    onClick={() => { window.open(" https://ctpstaking.seedcaptain.io/"); }}
                  >
                    Stake
                  </NavDropdown.Item>

                  <div className="ml-3">
                    <NavDropdown
                      className="sub-drop-down"
                      id="dropdown-button-drop-end"
                      drop="down"
                      title="Buy"
                    >
                      <NavDropdown.Item
                        id="nav-dropdown-dark-example"
                        onClick={() => { window.open("https://www.bibox.com/en/exchange/full-screen/CTP_USDT"); }}
                      >
                        Bibox
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        id="nav-dropdown-dark-example"
                        onClick={() => { window.open("https://app.jexchange.io/searchoffers?token_a=CTP-298075&token_b=WEGLD-bd4d79"); }}
                      >
                        Jexchange
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  <div className="ml-3">
                    <NavDropdown
                      className="sub-drop-down"
                      id="dropdown-button-drop-end"
                      drop="down"
                      title="Info"
                    >
                      <NavDropdown.Item
                        id="nav-dropdown-dark-example"
                        onClick={() => { window.open("https://www.coingecko.com/en/coins/captain-planet"); }}
                      >
                        Coingecko
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        id="nav-dropdown-dark-example"
                        onClick={() => { window.open("https://coinmarketcap.com/currencies/captain-planet/"); }}
                      >
                        Coinmarketcap
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </NavDropdown>

                <HashLink to={routeNames.home + '#community'} className='custom-navbar-button'>
                  Community
                </HashLink>

                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title="Community Tools"
                  className='custom-navbar-button'
                  style={{ color: 'white' }}
                >
                  {/* <NavDropdown.Item
                  id="nav-dropdown-dark-example"
                  onClick={() => { window.open(routeNames.home + '#community', "_self"); }}
                >
                  Community
                </NavDropdown.Item> */}

                  <NavDropdown.Item
                    id="nav-dropdown-dark-example"
                    onClick={() => { window.open("https://captainplanet.club/nfttools"); }}
                  >
                    NFT minter
                  </NavDropdown.Item>
                </NavDropdown>

                <Link to={routeNames.mint} className='custom-navbar-button'>
                  Seed Investors
                </Link>

                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title="Incubator"
                  className='custom-navbar-button'
                  style={{ color: 'white' }}
                >
                  <NavDropdown.Item
                    id="nav-dropdown-dark-example"
                    onClick={() => handleRouter(routeNames.zaya)}
                  >
                    ZayaAI
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    id="nav-dropdown-dark-example"
                    onClick={() => handleRouter(routeNames.tcl)}
                  >
                    The Cursed Land
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item
                    id="nav-dropdown-dark-example"
                    onClick={() => handleRouter(routeNames.xlvrg)}
                  >
                    xLeverage
                  </NavDropdown.Item> */}
                </NavDropdown>
                {/* <Link to={routeNames.home} className='custom-navbar-button'>
                Contact
              </Link> */}

                {/* <div className='custom-navbar-button auth-button text-center' onClick={() => setRevealButtons(!revealButtons)}>
                  <span>Mint CTP NFTs</span>
                </div> */}
                {
                  isLoggedIn ? (
                    <div className='custom-navbar-button auth-button text-center' onClick={handleLogout}>
                      <span>Disconnect</span>
                    </div>
                  ) : (
                    <div className='custom-navbar-button auth-button text-center' onClick={() => handleRouter(routeNames.unlock)}>
                      <span>Connect</span>
                    </div>
                  )
                }
              </Nav>
            </BsNavbar.Collapse>
          </div>
        </BsNavbar>

        {/* <div
          className={clsx('addition-bar py-3', {
            'addition-bar-displaying': revealButtons,
          })}

          style={{
            height: 68
          }}
        >
          {
            revealButtons && (
              <ClickAwayListener
                onClickAway={() => setRevealButtons(!revealButtons)}
              >
                <div className='additional-but-box'>
                  <div className='addition-but' onClick={() => { window.open("https://mint.seedcaptain.io/water-rings"); }}>Water Rings</div>
                  <div className='addition-but' onClick={() => { window.open("https://mint.seedcaptain.io/"); }}>Fire Rings</div>
                  <Link to={routeNames.mint} style={{ textDecoration: 'none' }}
                    onClick={() => {
                      setExpanded(false);
                      setRevealButtons(false);
                    }}
                  >
                    <div className='addition-but'>
                      Seed Investors
                    </div>
                  </Link>
                </div>
              </ClickAwayListener>
            )
          }
        </div> */}
      </div>
    </ClickAwayListener>
  );
};

export default Navbar;
