import { AbiRegistry } from '@multiversx/sdk-core/out';
import sftMintAbiJson from './abi/sft-mint.abi.json';
import vestingAbiJson from './abi/vesting-sc.abi.json';

const SftMintAbi = AbiRegistry.create(sftMintAbiJson as any);
const VestingAbi = AbiRegistry.create(vestingAbiJson as any);

export const contractAddress =
  'erd1qqqqqqqqqqqqqpgq4gdcg0k83u7lpv4s4532w3au9y9h0vm70eqq6m8qk2';

export const dAppName = 'CTP';

export const SOCIAL_WEBSITE_URL = 'https://synchronic.software/';

export const walletConnectV2ProjectId = '1598ff0ae61337b1759cc69884727875';

export const isDev = false;

export const contracts = {
  'SeedInvestorsSftMint': {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqd52kvh6df0y3mq44y77e89w3mtvdqkf7kqyscds9h3' : 'erd1qqqqqqqqqqqqqpgqt4c6ar722k64mdmlv82z5a2khymwy25vkqysh0rqm8',
    abi: SftMintAbi,
  },
  'ZayaVesting': {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqhcmyquh29mydm9lz3ju23m65r22kdupr5zvsfv6wlf' : 'erd1qqqqqqqqqqqqqpgq9drrzr9fgtnf08r7kuw0ntrmzlp3vm6nuslsw5gxeu',
    abi: VestingAbi,
  },
  'TclVesting': {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgq9w0xe9lh0v2ecaswrvkvavm4m936pncu0ufseuvnf8' : 'erd1qqqqqqqqqqqqqpgq97axlw2euvr3kenudu6xlch8zwmz2ah50ufspnfks0',
    abi: VestingAbi,
  },
  'XlvrgVesting': {
    address: isDev ? '' : '',
    abi: VestingAbi,
  },
};

export const UsdcTokenId = isDev ? 'USDC-350c4e' : 'USDC-c76f1f';

export const FireRings = isDev ? 'CRB-a8f4f9' : 'CTPFIRE-37931b';
export const WaterRings = isDev ? 'CRB-e54100' : 'CTPWATER-e268f8';

export const InvestorNft = isDev ? 'RTL-ffcc4e' : 'SEEDINV-9e0663';